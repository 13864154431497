import { Injectable } from '@angular/core';

import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => this.handleError(err)));
    }

    private handleError(response: HttpErrorResponse): Observable<any> {
        if (response.status === 401) {
            if (response.error?.error) {
                return throwError(response.error.error);
            } else {
                return throwError('Incorrect login credentials.');
            }
        }
        return throwError(response);
    }
}
