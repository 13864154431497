export const environment = {
  production: false,
  pubnub_topic: 'fi.clara.helpingapp',
  pubnub_environment: 'development',
  authUrl: 'https://mobiletest.clara.fi/mobile/authenticate/',
  baseApiUrl: 'https://mobiletest.clara.fi/mobile/1/',
  helpingAppUrl: 'helpingapp-test.clara.fi',
  opentok_base_url: '',
  opentok_api_key: '47182754',
  secretPassphrase: 'Secret Passphrase For Helping App'
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --local` replaces `environment.ts` with `environment.local.ts`.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
