import { Component, NgZone, OnDestroy } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Platform } from '@ionic/angular';

import { environment } from '../environments/environment';
import { appDefaultLang } from './globals';
import moment from 'moment';
import { AuthService } from '../providers/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserDataService } from '../providers/user-data-service';
import { RouterService } from '../providers/router-service';
import { Events } from '../providers/events';
import { filter } from 'rxjs/operators';
import { ChatService } from '../providers/chat-service';
import { ToastService } from '../providers/toast-service';
import { TaskService } from '../providers/task-service';
import { NavDataService } from '../providers/nav-data-service';
import { InitializeService } from '../providers/initialize-service';
import { ValidationService } from '../providers/validation-service';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Token } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
    private prevUrl: string;
    token: Token;

    constructor(
        private initializeService: InitializeService,
        private navDataService: NavDataService,
        private taskService: TaskService,
        private toaster: ToastService,
        private chatService: ChatService,
        private activatedRoute: ActivatedRoute,
        private platform: Platform,
        private translate: TranslateService,
        private authService: AuthService,
        private router: Router,
        private zone: NgZone,
        private userData: UserDataService,
        private event: Events,
        private routerService: RouterService,
    ) {
        ValidationService.setTranslationService(translate);
        this.platform.ready().then((data) => {
            if (Capacitor.isPluginAvailable('StatusBar')) {
                StatusBar.show();
            }
            SplashScreen.hide();

            // Set last used language if exists else set app default language
            this.authService.getLastUsedLanguage()
                .then((lang) => {
                    if (lang !== null && typeof (lang) !== 'undefined') {
                        this.translate.setDefaultLang(lang);
                        this.authService.currentLanguage = lang;
                        moment.locale(lang);
                    } else {
                        this.authService.setLastUsedLanguage(appDefaultLang);
                        this.translate.setDefaultLang(appDefaultLang);
                        this.translate.use(appDefaultLang);
                        moment.locale(appDefaultLang);
                    }
                });
            this.initializeApp();
        });

        router.events
            .pipe(filter(ev => ev instanceof NavigationEnd))
            .subscribe((ev: NavigationEnd) => {
                routerService.setPrevUrl(this.prevUrl);
                this.prevUrl = ev.urlAfterRedirects || ev.url;
            });
    }

    ngOnDestroy() {
        this.navDataService.setNavParams({});
    }

    async initializeApp() {
        await this.initializeService.initPushNotification();
        this.checkUserCredentials();

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                // Example url: https://helpingapp.clara.fi/open-task-id/123456
                // slug = /open-task-id/123456
                const slug = event.url.split(environment.helpingAppUrl).pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
                // If no match, do nothing - let regular routing logic take over
            });
        });
    }

    checkUserCredentials() {
        // If sessionId exists already(fetched from storage),
        // go on tabs page
        const isAppInitialized = this.initializeService.initializeApp();
        this.authService.fetchSavedCredentials()
            .then((res: boolean | undefined) => {
                if (
                    res && this.authService.sessionId != null && isAppInitialized &&
                    !this.router.url.includes('/selected-task/') && !this.router.url.includes('/selected-link/') && !this.router.url.includes('/open-task-id/')
                ) {
                    this.router.navigate(['/tabs']);
                } else if (this.router.url.startsWith('/login-with-token')) {
                    // do not redirect from login-with-token route
                } else if (this.router.url.startsWith('/open-task-id')) {
                    // do not redirect from open-task-id route, because OpenTaskIdPage will check the sessionId
                } else if (!isAppInitialized || !res || !this.authService.sessionId) {
                    this.router.navigate(['/']);
                }
            });
    }
}
