// App version, as shown on the settings
export const appVersion = '2.3.0';
export const buildNumber = 2045;
export const versionNumber = appVersion + ' (' + buildNumber.toString() + ')';

// App default language
export const appDefaultLang = 'fi';

// Roles
export const roleCoordinator = 'coordinator';
export const roleVolunteer = 'volunteer';
export const roleCustomer = 'customer';

// Status
export const statusTaskDone = 'done';
export const statusLinkDone = 'ended';

// Visibility types for chats
export const PERSONAL_CHAT_VISIBILITY_TYPE = 1;     // A one-to-one chat between volunteer and coordinator
export const TASK_GROUP_CHAT_VISIBILITY_TYPE = 5;   // A group chat between all volunteers for task service
export const TASK_TEAM_CHAT_VISIBILITY_TYPE = 6;    // A group chat between volunteer and coordinators for task service
