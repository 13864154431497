import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Platform, ToastController } from '@ionic/angular';

import { AuthService } from './auth-service';
import { PlatformService } from './platform-service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from './user-data-service';
import { PubNubAngular } from 'pubnub-angular2';
import { Events } from './events';
import { map } from 'rxjs/operators';
import { Chat } from '../pages/chat/chat.model';
import { ChatService } from './chat-service';
import { ToastService } from './toast-service';
import { TaskService } from './task-service';
import { Task } from '../components/task/task.model';
import { NavDataService } from './nav-data-service';
import { User } from '../pages/login-page/user.model';
import { FetchTasksResponseInterface } from './open-tasks-service';
import { Storage } from '@ionic/storage';
import { environment } from '../environments/environment';
import { Token, PushNotifications } from '@capacitor/push-notifications'
import { CryptoJsService } from './crypto-js-service';
import { CacheService } from './cache-service';
import { Settings } from './settings-model';
import { SettingsService } from './settings-service';

@Injectable()
export class InitializeService {
    token: Token;
    subscribeKey: string;
    publishKey: string;
    userUuid: string;
    callToast: any;
    isCallToastOpened: boolean = false;
    showApplyForVolunteering: boolean = false;
    showPublicTasks: boolean = false;
    privacyPolicyUrl: string;

    private getSettingsSubject = new Subject<any>();

    constructor(
        private platformService: PlatformService,
        private navDataService: NavDataService,
        private taskService: TaskService,
        private toaster: ToastService,
        private chatService: ChatService,
        private activatedRoute: ActivatedRoute,
        private platform: Platform,
        private translate: TranslateService,
        private authService: AuthService,
        private router: Router,
        private userData: UserDataService,
        private pubnub: PubNubAngular,
        private events: Events,
        private storage: Storage,
        private cryptoJsService: CryptoJsService,
        private toastController: ToastController,
        private cacheService: CacheService,
        private settingsService: SettingsService
    ) {}

    // iOS 9 helper for decoding base64 strings
    public decodeFromBase64(input) {
        input = input.replace(/\s/g, '');
        return atob(input);
    }

    async initializeApp() {
        this.fetchSettings();
        if (this.authService.userId) {
            this.userData.fetchUserData()
                .subscribe((user: User) => {
                    this.userData.user = user;
                });
        }

        if (!this.authService.subscribeKey && !this.authService.publishKey) {
            this.subscribeKey = await this.storage.get('subscribeKey');
            this.publishKey = await this.storage.get('publishKey');
            this.userUuid = await this.storage.get('userUuid');
            if (this.subscribeKey) {
                this.subscribeKey = this.cryptoJsService.decrypt(this.subscribeKey);
            }
            if (this.publishKey) {
                this.publishKey = this.cryptoJsService.decrypt(this.publishKey);
            }
        } else {
            this.subscribeKey = this.cryptoJsService.decrypt(this.authService.subscribeKey);
            this.publishKey = this.cryptoJsService.decrypt(this.authService.publishKey);
            this.userUuid = this.authService.userUuid;
        }

        const userId = await this.storage.get('userId');
        if (userId) {
            this.cacheService.startJob();
        }

        if (this.subscribeKey && this.publishKey) {
            if (!this.platformService.isProduction()) {
                console.log('User uuid: ' + this.userUuid);
            }

            this.pubnub.init({
                subscribeKey: this.subscribeKey,
                publishKey: this.publishKey,
                ssl: true,
                uuid: this.userUuid
            });

            this.pubnub.subscribe({
                channels: this.authService.channels,
                triggerEvents: ['message']
            });

            const chatChannels: string[] = this.authService.channels;

            this.pubnub.getMessage(chatChannels, msg => {
                // Handle incoming call
                const content = msg.message.content;
                if (content === 'call:incoming') {
                    const incomingCallData = {
                        callerName: msg.message.callerName,
                        callerPrivateChanel: msg.message.callerPrivateChanel,
                        session: msg.message.session,
                        participantPrivateChannel: msg.message.participantPrivateChannel,
                        participantName: msg.message.participantName,
                        participantToken: msg.message.participantToken,
                    };

                    if (this.platformService.isIos() && !this.platformService.isWebRtcSupported()) {
                        this.showWarningIosToast(incomingCallData);
                    } else {
                        this.showIncomingCallToast(incomingCallData);
                    }
                } else if (content === 'call:cancel-by-caller') {
                    if (this.isCallToastOpened) {
                        this.callToast.dismiss();
                        this.isCallToastOpened = false;
                    }
                }
                // Handle incoming message
                else {
                    this.events.publish('chat:messageReceived');
                    this.events.publish('tab:updateChatBadge');
                }
            });

            // Only for mobile app
            if (this.authService.deviceToken) {
                if (this.platformService.isNative()) {
                    this.cacheService.startJob();
                    this.pubnub.push.addChannels(
                        {
                            channels: [this.authService.channels],
                            device: this.authService.deviceToken,
                            pushGateway: this.platformService.service,
                            environment: environment.pubnub_environment,
                            topic: environment.pubnub_topic,
                        },
                        (status) => {
                            if (status.error) {
                                console.log('create channel error: ', status);
                            } else {
                                if (!this.platformService.isProduction()) {
                                    console.log('create channels success: ' + this.authService.channels);
                                }
                            }
                        });

                    this.pubnub.push.addChannels(
                        {
                            channels: [this.authService.privateChannel],
                            device: this.authService.deviceToken,
                            pushGateway: this.platformService.service,
                            environment: environment.pubnub_environment,
                            topic: environment.pubnub_topic,
                        },
                        (status) => {
                            if (status.error) {
                                console.log('create private channel error:', status);
                            } else {
                                if (!this.platformService.isProduction()) {
                                    console.log('create private channel success: ' + this.authService.privateChannel);
                                }
                            }
                        });
                }
            }
            return true;
        }
        return false;
    }

    async showIncomingCallToast(incomingCallData) {
        let header = '';
        let accept = '';
        let decline = '';
        this.translate.get('videoIncomingCallToast.header').subscribe(
            value => {
                header = value;
            });
        this.translate.get('videoIncomingCallToast.accept').subscribe(
            value => {
                accept = value;
            });
        this.translate.get('videoIncomingCallToast.decline').subscribe(
            value => {
                decline = value;
            });
        const sessionData = {
            session: incomingCallData.session,
            token: incomingCallData.participantToken,
            participantPrivateChannel: incomingCallData.participantPrivateChannel,
            participantName: incomingCallData.participantName,
            callerPrivateChanel: incomingCallData.callerPrivateChanel,
            callerName: incomingCallData.callerName
        };
        this.callToast = await this.toastController.create({
            header: incomingCallData.callerName + ' ' +  header,
            position: 'top',
            cssClass: 'incoming-call-toast',
            buttons: [
                {
                    text: accept,
                    side: 'start',
                    icon: 'call',
                    handler: () => {
                        this.isCallToastOpened = false;
                        this.navDataService.setNavParams({sessionData});
                        this.router.navigate([`/video-call`]);
                    }
                }, {
                    text: decline,
                    icon: 'close-circle',
                    role: 'cancel',
                    handler: () => {
                        this.isCallToastOpened = false;
                        this.pubnub.publish({
                            channel: incomingCallData.callerPrivateChanel,
                            message: {
                                content: 'call:decline',
                                participantName: incomingCallData.participantName
                            }
                        });
                    }
                }
            ]
        });
        await this.callToast.present();
        this.isCallToastOpened = true;
    }

     async showWarningIosToast(incomingCallData?: any) {
         let iosVersionNeedUpdate = '';
         let okButton = '';
         this.translate.get('selectedTaskPage.update_ios_version').subscribe(
             data => iosVersionNeedUpdate = data
         );
         this.translate.get('videoIncomingCallToast.ok-button').subscribe(
             data => okButton = data
         );
        this.callToast = await this.toastController.create({
            header: iosVersionNeedUpdate,
            position: 'top',
            buttons: [
                {
                    text: okButton,
                    role: 'cancel',
                    handler: () => {
                        this.isCallToastOpened = false;
                        this.pubnub.publish({
                            channel: incomingCallData?.callerPrivateChanel,
                            message: {
                                content: 'call:unsupported-ios-version',
                                participantName: incomingCallData?.participantName
                            }
                        });
                    }
                }
            ]
        });
        await this.callToast.present();
        this.isCallToastOpened = true;
    }

    async updateChannels(taskChannel){
        const updatedChannels = this.authService.channels;
        const newChannel = this.decodeFromBase64(taskChannel);

        if (updatedChannels.indexOf(newChannel) === -1) {
            updatedChannels.push(newChannel);
            await this.storage.set('channels', updatedChannels);

            this.pubnub.subscribe({
                channels: updatedChannels,
                triggerEvents: ['message']
            });

            if (this.platformService.isNative()) {
                this.pubnub.push.addChannels(
                    {
                        channels: [updatedChannels],
                        device: this.authService.deviceToken,
                        pushGateway: this.platformService.service,
                        environment: environment.pubnub_environment,
                        topic: environment.pubnub_topic,
                    }).catch(err => console.log('update channel error: ', err));
            }
        }
    }

    onTaskNotificationClick(taskId: number) {
        this.taskService.fetchTask(taskId)
            .pipe(map((res: FetchTasksResponseInterface) => res.tasks[0] as Task))
            .subscribe((task: Task) => {
                if (task) {
                    this.router.navigate([`/selected-task/${task.id}`], {state: {refreshCache: true}});
                } else {
                    this.router.navigate(['/tabs/own-tasks']);
                }
            });
    }

    onChatNotificationClick(chatId) {
        this.chatService.fetch()
            .pipe(map(res => res.chats as Chat[]))
            .subscribe(res => {
                const chat = res.find(item => item.id === chatId);
                if (chat) {
                    this.navDataService.setNavParams(chat);
                    this.router.navigate(['/selected-chat']);
                } else {
                    this.toaster.showToast({text: 'pushNotifications:invalid_chat_id', duration: 7000});
                }
            });
    }

    fetchSettings() {
        this.settingsService.fetchSettingsData()
            .pipe(map((res: any) => res.settings))
            .subscribe( (data: Settings) => {
                const cacheRefreshInterval = data.cache_refresh_interval_in_minutes;
                const cacheExpirationTime = data.cache_expiration_time_in_minutes;
                const showApplyForVolunteering = data.show_apply_for_volunteering;
                const showPublicTasks = data.show_public_tasks;

                // if cache_refresh_interval_in_minutes setting is zero, there would not be any cron job service started
                if (cacheRefreshInterval && cacheExpirationTime) {
                    this.cacheService.createJob(cacheRefreshInterval, cacheExpirationTime);
                }

                // if cache_expiration_time_in_minutes setting is zero, no task/link data would be cached
                if (cacheExpirationTime) {
                    this.cacheService.cacheUpdate = true;
                }

                if (showApplyForVolunteering) {
                    this.showApplyForVolunteering = true;
                }

                if (showPublicTasks) {
                    this.showPublicTasks = true;
                }
                this.privacyPolicyUrl = data.privacy_policy_url;
                this.getSettingsSubject.next({ showPublicTasks: this.showPublicTasks, showApplyForVolunteering: this.showApplyForVolunteering, privacyPolicyUrl: this.privacyPolicyUrl });
            });
    }

    onFetchSettings(): Observable<any> {
        return this.getSettingsSubject.asObservable();
    }

    async initPushNotification() {
        // No push notifications unless native build
        if (!this.platformService.isNative() || this.authService.deviceToken) {
            return;
        }

        // Request Push Notification permission.
        const request = await PushNotifications.requestPermissions();
        if (request?.receive === 'granted') {
            // The user has granted permission to register to APNS/FCM.
            PushNotifications.register();
        } else {
            // The user has rejected our request to register with APNS/FCM.
        }

        // On successful registration, the device will send us the Device Token.
        PushNotifications.addListener('registration',
            (token: Token) => {
                if (!this.platformService.isProduction()) {
                    console.log('Push registration success! Device Token: ' + token.value);
                }
                this.authService.deviceToken = token.value;
            }
        );

        // If registration fails, alert the error message.
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.log('Error registering for Push: ' + JSON.stringify(error));
            }
        );

        // On Push Notification received, alert the notification payload.
        PushNotifications.addListener('pushNotificationReceived',
            (notification: any) => {
                if (!this.platformService.isProduction()) {
                    console.log('Push received: ' + JSON.stringify(notification));
                }
            }
        );

        PushNotifications.addListener('pushNotificationActionPerformed', (msg) => {
            PushNotifications.removeAllDeliveredNotifications();

            if (!this.platformService.isProduction()) {
                console.log('Notification pressed: ', JSON.stringify(msg));
            }

            const type = msg.notification.data.type ? msg.notification.data.type : msg.notification.data.aps.type;
            const objectId = msg.notification.data.object_id ? msg.notification.data.object_id : msg.notification.data.aps.object_id;
            if (type) {
                this.authService.fetchSavedCredentials()
                    .then(() => {
                        if (type === 'chat') {
                            objectId ? this.onChatNotificationClick(objectId) :
                                this.toaster.showToast({text: 'pushNotifications:missing_chat_id', duration: 7000});
                        }
                        if (type === 'task') {
                            objectId ? this.onTaskNotificationClick(objectId) :
                                this.toaster.showToast({text: 'pushNotifications:missing_task_id', duration: 7000});
                        }
                    });
            }
        });

        PushNotifications.removeAllDeliveredNotifications();
    }
}
