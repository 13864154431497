import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'chatBubbleTime',
})
export class ChatBubbleTime implements PipeTransform {

    transform(value: string) {
        const date = moment(value);
        return date.format('HH:mm');
    }
}
